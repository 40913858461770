import React from "react"

export default ({className="",image}) => {
  return <figure className={`image-centered ${className}`}>
    {image.extension === "svg" ? <img
      src={image.url}
      alt={image.title}
      /> : <img
        src={`${image.imageTransforms.src}`}
        srcSet={`${image.imageTransforms.srcset}`}
        alt={image.title}
        sizes="100vw"
      />}
  </figure>
}

export const ImgBg = ({image, className, height=60}) => {
  if (image.extension === "svg") {
    return <figure className="resource-image resource-svg">
      <img src={image.url} alt={image.title} />
    </figure>
  }

   const focalPoint = image.imageTransforms.focalPoint.map(fp => {
    return `${fp*-100}%`
  })
  const imgPos = image.imageTransforms.focalPoint.map(fp => {
    return `${fp*100}%`
  })

  return <figure className={`image-centered image-abs ${className}`} style={{
    paddingBottom: `${height}%`
  }}>
    <img
      src={`${image.imageTransforms.src}`}
      srcSet={`${image.imageTransforms.srcset}`}
      alt={image.title}
      sizes="100vw"
      style={{
        left: imgPos[0],
        top: imgPos[1],
        transform: `translate(${focalPoint})`
      }}
    />
  </figure>
}
